var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemCRUDDialog',{attrs:{"mode":"update","item":_vm.user,"urlPrefix":"/user"},on:{"save":function (updatedUser) { return (_vm.user = updatedUser); },"close":_vm.goToPreviousPage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$t('user.firstname'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.firstname),callback:function ($$v) {_vm.$set(item, "firstname", $$v)},expression:"item.firstname"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.lastname'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.lastname),callback:function ($$v) {_vm.$set(item, "lastname", $$v)},expression:"item.lastname"}}),_c('v-text-field',{attrs:{"label":_vm.$t('user.email'),"type":"email","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('v-file-input',{attrs:{"label":_vm.$t('user.avatar'),"accept":"image/png, image/jpeg","clearable":"","show-size":"","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.image.fileObject),callback:function ($$v) {_vm.$set(item.image, "fileObject", $$v)},expression:"item.image.fileObject"}}),_c('AdminUserAddressTable',{attrs:{"editable":"","userAddresses":item.addresses},on:{"crudDialogSave":function (updatedAddresses) { return (item.addresses = updatedAddresses); }}}),_c('v-switch',{attrs:{"label":((_vm.$t('user.changePassword')) + ": " + (_vm.changePassword ? _vm.$t('label.yes') : _vm.$t('label.no')))},on:{"change":function (value) {
                    item.passwordWdh = item.password = value ? '' : null;
                }},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}}),(_vm.changePassword)?[_c('PasswordTextField',{attrs:{"label":_vm.$t('user.password'),"required":"","rules":[function (v) { return !!v; }]},model:{value:(item.password),callback:function ($$v) {_vm.$set(item, "password", $$v)},expression:"item.password"}}),_c('PasswordTextField',{attrs:{"label":_vm.$t('user.passwordWdh'),"required":"","rules":[
                    function (v) { return !!v; },
                    function (v) { return v == item.password ||
                        _vm.$t('form.validation.passwordDontMatch'); } ]},model:{value:(item.passwordWdh),callback:function ($$v) {_vm.$set(item, "passwordWdh", $$v)},expression:"item.passwordWdh"}})]:_vm._e()]}}]),model:{value:(_vm.isCRUDDialogVisible),callback:function ($$v) {_vm.isCRUDDialogVisible=$$v},expression:"isCRUDDialogVisible"}})}
var staticRenderFns = []

export { render, staticRenderFns }